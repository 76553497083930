<template>
    <div>
        <app-page-header v-if="!showLoadingWindow"></app-page-header>
        <v-container grid-list-xl>
            <edit-parking-permit v-if="computedPermitType == 1" :uuid="uuid" :permitId="computedPermitId" :permit="computedPermit"></edit-parking-permit>
            <edit-promenada-permit v-if="computedPermitType == 2" :uuid="uuid" :permitId="computedPermitId" :permit="computedPermit"></edit-promenada-permit>
        </v-container>
    </div>
</template>

<script>
const EditParkingPermit = () => import('@/components/admin/EditParkingPermit.vue')
const EditPromenadaPermit = () => import('@/components/admin/EditPromenadaPermit.vue')
import { EventBus } from '@/main.js'

export default {
    components: {
        EditParkingPermit,
        EditPromenadaPermit
    },

    data: () => ({
        uuid: null,
        permitId: null,
        permit: null,
        permitType: null,
        loading: true
    }),

    computed: {
        showLoadingWindow() {
            if(this.loading) {
                return true;
            }

            return false;
        },

        computedPermitId() {
            return this.permitId;
        },

        computedPermit() {
            return this.permit
        },

        computedPermitType() {
            return this.permitType
        }
    },

    methods: {
        getPermitData(uuid) {
            this.$store.commit('SET_LOADER_TEXT', 'Pridobivanje podatkov o dovolilnici je v teku...')
            this.$store.commit('SET_LOADER', true)
            var vm = this
            this.$store.dispatch("EDIT_PERMIT", uuid)
            .then(response => {
                //window.console.log(response.data.data)
                vm.permit = response.data.data
                vm.permitType = (response.data.data.permit_type != null) ? response.data.data.permit_type.type : null
                vm.permitId = response.data.data.id
            })
            .catch(error => {
                window.console.log(error)
            })
            .finally(() => {
                setTimeout(() => { 
                    vm.loading = false
                    this.$store.commit('SET_LOADER', false) 
                }, 400)
            })
        }
    },

    created() {
        this.$store.dispatch('ROUTE_NAME', this.$route.name)
        //window.console.log(this.$route.name)
    },

    mounted() {
        var vm = this
        this.uuid = this.$route.params.id
        if(this.uuid != null) {
            this.getPermitData(this.uuid)
        }

        //refresh dovolilnice
        EventBus.$on('GET_PERMIT_DATA', function(data){
            if(data != null && data != undefined) {
                vm.$store.dispatch("EDIT_PERMIT", vm.uuid)
                .then(response => {
                    vm.permit = response.data.data
                    vm.permitType = (response.data.data.permit_type != null) ? response.data.data.permit_type.type : null
                    vm.permitId = response.data.data.id
                    setTimeout(() => {
                        EventBus.$emit('REFRESH_PERMIT_DATA', {
                            success: true
                        })
                    }, 200)
                })
                .catch(error => {
                    window.console.error("### ERROR EditPermit@refreshPermitData ###")
                    window.console.log(error)
                    EventBus.$emit('REFRESH_PERMIT_DATA', {
                        success: false
                    })
                })
            } else {
                setTimeout(() => {
                    EventBus.$emit('REFRESH_PERMIT_DATA', {
                        success: false
                    })
                }, 200)
            }
        })

    },

    destroyed() {
        EventBus.$off('GET_PERMIT_DATA')
    }
}

</script>

<style scoped>

</style>